<template>
<div>
    <Box>
      <SpacerRow />
      <ErrorRow :error="error"> </ErrorRow>
      
      <TitleRow>  Add User </TitleRow>
      <Row>
        <Column>
          <Box :showBorder="true">
            <TitleRow :subtitle="true" align="left"> First and last name </TitleRow>
            <ValueRow align="left">  Add someone to "{{ companyName(company) }}" </ValueRow>
            <Row>
              <Column :width="8">
                <Box>
                  <KeyValue
                    :mandatory="true"
                    :required="isRequired(FIELDS.FIRST)"
                    title="first"
                    placeholder="First Name" 
                    v-on:value="setValue" />
                </Box>
              </Column>
              <Column :width="7">
                <Box>
                  <KeyValue
                    :mandatory="true"
                    :required="isRequired(FIELDS.LAST)"
                    title="last" 
                    placeholder="Last Name" 
                    v-on:value="setValue" />
                </Box>
              </Column>
            </Row>
          </Box>
        </Column>
      </Row>

      <Row>
          <Column>
            <Box :showBorder="true">
                
              <TitleRow :subtitle="true" align="left"> Drivers License </TitleRow>
              <Row>
                <Column :width="15">
                  <Box >
                    <KeyList
                      :title="UserFields.LICENSE_CLASS"
                      placeholder="License Class"
                      :defaultValue="licenseClass"
                      :options="licenseClassNames"
                      hint="Driver's license class will be used by the mobile app to help with weight compliance"
                      v-on:value="setValue"
                    />
                  </Box>
                  <Spacer />
                  <Box>
                    <KeyDate 
                      :title="UserFields.LICENSE_EXPIRY"
                      placeholder="Expires" 
                      :defaultValue="licenseExpiry" 
                      v-on:value="setValue" />
                  </Box>
                </Column>
              </Row>

            </Box>
          </Column>
      </Row>
                  
      <Row>
        <Column>
          <Box :showBorder="true">
            <TitleRow :subtitle="true" align="left"> Role, Employee ID </TitleRow>
            <KeyList
              title="UserRoleMap"
              placeholder="Role"
              :defaultValue="defaultRole"
              :options="roles"
              hint="Every user must have a role, the driver role is the simplest role and lets them use only the app. The manager and owner roles will give them access to the portal as well as the app."
              v-on:value="setEmployeeArrayValue" />
            
            <KeyValue 
              :title="EmployeeFields.EMPLOYMENT_ID" 
              placeholder="Employee ID" 
              hint="Employee ID is required as your organization will share devices."
              :required="isRequired(EmployeeFields.EMPLOYMENT_ID)"
              v-on:value="setValue" />
          </Box>
        </Column>
      </Row>
    
      <SpacerRow />
      
      <Row>
        <Column :width="5">
        </Column>
        <Column :width="5">
          <div v-if="isSaving">
            <div class="rc-modal-green">
              <h1>Adding new user</h1>
              <p>Please wait...</p>
            </div>
          </div>

          <Button :go="true" :disabled="false" v-on:click="add">
          Add
          </Button>
        </Column>
        <Column :width="5">
        </Column>
      </Row>
    </Box>
    
    <SpacerRow />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConstUtils from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import User from '@/domain/model/user/User.js';
//import UserRoleMap from '@/domain/model/role/RoleMap.js';
//import UserRole from '@/domain/model/role/Role.js';
import Employee from '@/domain/model/employee/Employee.js';

import CompanyUtils from '@/views/portal/companys/Utils.js';
import ErrorRow from '@/components/row/ErrorRow.vue';
import KeyList from "@/components/input/KeyList.vue";
import KeyValue from '@/components/input/KeyValue.vue';
import KeyDate from '@/components/input/KeyDate.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import Spacer from '@/portals/shared/library/spacer/Spacer.vue';

import Button from '@/portals/shared/library/button/Button.vue';

export default {
  name: 'portal-employees-add',
  components: {
    ErrorRow,
    KeyValue,
    Button,
    KeyList, KeyDate,
    SpacerRow, Spacer,
    Box, Row, Column,
    TitleRow, ValueRow,
  },
  data() {
    return {
      id: this.$route.params['id'], 
      error: null,
      users: [],
      kvps: {},
      cmd: null,
      isAddDisabled: true,
      isSaving: false,
      missing: null,
      FIELDS: User.FIELDS,
      UserFields: User.FIELDS,
      EmployeeFields: Employee.FIELDS,
      defaultRole: null,
      licenseClass: null,
      licenseExpiry: null,
    };
  },
  computed: {
    ...mapGetters([
      'auth_client',
      'auth_user',
      'auth_connected',
      'employees_found',
      'companys_found',
      'users_store',
      'domain',
      ]),
    roles: function () {
      return ContentUtils.ROLES();
    },
    defaultRoleId: function() {
      return ContentUtils.ROLE_DRIVER()['@rid'];
    },
    company: function() {
      return this.companys_found.map[this.id];
    },
    licenseClassNames: function() {
      return ContentUtils.LICENSES();
    },
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.send()
      }
    },
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    } else {
      this.defaultRole = this.defaultRoleId;
      this.setValue({ 
        key: 'UserRole', 
        value: this.defaultRoleId });
    }
  },
  methods: {
    isEven: function(position) {
      return StringUtils.isEven(position);
    },
    isRequired: function(value) {
      return this.missing === value;
    },
    companyName: function(company) {
      return CompanyUtils.companyName(company);
    },
    
    companyId: function() {
      return CompanyUtils.companyId(self.company);
    },
    
    setValue: function(kvp) {
      kvp.value = StringUtils.toString(kvp.value);
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
      if (Object.keys(this.kvps).length >= 3) {
        this.isAddDisabled = false;
      }
    },
    setEmployeeArrayValue: function (kvp) {
      kvp.value = [kvp.value];
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
    },
    add: function() {
      if (this.auth_connected) {
        this.send();
      } else {
        this.$connect();  
      }
    },
    
    send: function() {
      if (!this.requirementsMet()) {
        return;
      }
      this.isAddDisabled = true;
      this.isSaving = true;
      var event = this.domain.events().employeeAdd(this.kvps);
      event.send(this.listener);
    },
    
    listener: function(rcEvent) {
      if (rcEvent.error()) {
        this.error = rcEvent.error();
      } else {
        this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.EMPLOYEE.LIST });
      }
    },
    
    requirementsMet: function() {
      this.missing = null;
      var met = ContentUtils.hasValue(this.kvps, this.FIELDS.FIRST);
      if (!met) {
        this.missing = this.FIELDS.FIRST;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.FIELDS.LAST);
      if (!met) {
        this.missing = this.FIELDS.LAST;
        return met;
      }
      met = ContentUtils.hasValue(this.kvps, this.EmployeeFields.EMPLOYMENT_ID);
      if (!met) {
        this.missing = this.EmployeeFields.EMPLOYMENT_ID;
        return met;
      }
      return met;
    },
  }  
}
</script>